import type model from './model';
import { MENU_WIDGET_COMPONENT_IDS } from '../../appConsts/blocksIds';
import type { PopulatedMenu } from '../../types/menusTypes';
import type { Experiments, I$W, TFunction } from '@wix/yoshi-flow-editor';
import { state } from '../../states/RootState';
import { MENU_STATES } from '../../types/businessTypes';
import type { ControllerParams } from '../../types/widgets';
import { menusState } from 'root/states/MenusState';
import { dispatchState } from 'root/states/DispatchState';
import { SPECS } from 'root/appConsts/experiments';
import { openDispatchModal } from 'root/utils/utils';
import { availabilityStatusKeys } from 'root/availabilityStatusKeys';
import {
  LiveSiteClickFulfillmentOrigin,
  LiveSiteMenuStatusCardDisplayedPageName,
} from '@wix/restaurants-bi';

type BindAll = ControllerParams<typeof model>['$bindAll'];

export class MenusController {
  sectionsIdsMap: Record<string, string> = {};
  shouldShowErrorContent: boolean = false;

  constructor(
    private $bindAll: BindAll,
    private $w: I$W,
    private t: TFunction,
    private experiments: Experiments,
    private isEditor: boolean,
    private timezone: string,
    private locale: string,
    private isMemberLoggedIn: boolean
  ) {
    state.pubsub.subscribe('onScrollToMenu', this.scrollToMenu);
  }

  async init() {
    const isMenuStatusEnabled = this.experiments.enabled(SPECS.menuStatus);

    this.$bindAll({
      [MENU_WIDGET_COMPONENT_IDS.menuMultiStateBox]: {
        currentState: () => (menusState.isEmpty ? MENU_STATES.menuEmptystate : MENU_STATES.menu),
      },
      [MENU_WIDGET_COMPONENT_IDS.menuEmptyStateTitle]: {
        text: () =>
          menusState.hasError
            ? this.t('menu_olo.errorState.title')
            : this.t('menu_olo.emptyState.title'),
      },
      [MENU_WIDGET_COMPONENT_IDS.menuEmptyStateSubtitle]: {
        text: () =>
          menusState.hasError
            ? this.t('menu_olo.errorState.subTitle')
            : this.t('menu_olo.emptyState.subTitle'),
      },
      [MENU_WIDGET_COMPONENT_IDS.repeaterMenus]: {
        data: () => menusState.sortedMenusDto,
        // @ts-expect-error
        item: (itemData: PopulatedMenu & { _id: string }, bindItem) => {
          bindItem(MENU_WIDGET_COMPONENT_IDS.menuTitle, {
            text: () => menusState.getMenu(itemData.id)?.menuDto?.name || '',
            collapsed: () => !menusState.getMenu(itemData.id)?.menuDto?.name,
          });

          bindItem(MENU_WIDGET_COMPONENT_IDS.menuDescription, {
            text: () => menusState.getMenu(itemData.id)?.menuDto?.description || '',
            collapsed: () => !menusState.getMenu(itemData.id)?.menuDto?.description,
          });

          bindItem(MENU_WIDGET_COMPONENT_IDS.menuStatus, {
            collapsed: () => {
              const menu = menusState.getMenu(itemData.id);
              const isAvailable = !!menu?.isAvailable;
              const { shouldCollapseAvailabilityStatus } =
                menu?.getAvailabilityStatus({
                  locale: this.locale,
                  timezone: this.timezone,
                  t: this.t,
                  keys: availabilityStatusKeys.menu,
                }) || {};

              if (
                isMenuStatusEnabled &&
                !isAvailable &&
                menu?.nextAvailableTimeslot !== undefined
              ) {
                state.biReporterService?.reportOloMenuStatusCardDisplayedBiEvent({
                  menuId: itemData.id,
                  isButtonDisplayed: !!menu?.nextAvailableTimeslot,
                  pageName: LiveSiteMenuStatusCardDisplayedPageName.OLO_PAGE,
                  isCardDisplayed: !shouldCollapseAvailabilityStatus,
                });
              }
              return !isMenuStatusEnabled || isAvailable || !!shouldCollapseAvailabilityStatus;
            },
            deleted: () => this.isEditor,
          });

          if (!this.isEditor) {
            bindItem(MENU_WIDGET_COMPONENT_IDS.menuStatusText, {
              text: () => {
                const menu = menusState.getMenu(itemData.id);
                const { text = '' } =
                  menu?.getAvailabilityStatus({
                    locale: this.locale,
                    timezone: this.timezone,
                    t: this.t,
                    keys: availabilityStatusKeys.menu,
                  }) || {};

                return text;
              },
            });

            bindItem(MENU_WIDGET_COMPONENT_IDS.menuStatusButton, {
              label: () => {
                const dispatchType = dispatchState.selectedDispatchType.toLowerCase();
                return this.t(
                  `menu_olo.menuAvailabilityStatus.nextAvailable.${dispatchType}.button`
                );
              },
              onClick: () => {
                state.biReporterService?.reportOloLiveSiteClickOnFulfillmentBiEvent({
                  origin: LiveSiteClickFulfillmentOrigin.CLICK_MENU_STATUS_BUTTON_OLO_PAGE,
                  dispatchType: dispatchState.selectedDispatchType,
                  isMemberLoggedIn: this.isMemberLoggedIn,
                  menuId: itemData.id,
                });
                openDispatchModal({
                  onSave: async ({ dispatchType, dispatchInfo }) => {
                    dispatchState.update(dispatchType, dispatchInfo);
                    state.CartService?.setShippingDetails(dispatchState.getShippingDetails());
                    await menusState.updateAvailabilityStatus(
                      state.operation?.id,
                      dispatchInfo,
                      dispatchType
                    );
                  },
                  rootState: state,
                  dispatchState: dispatchState.state,
                });
              },
              collapsed: () => {
                const hasNextAvailableTime = !!menusState.getMenu(itemData.id)
                  ?.nextAvailableTimeslot;
                return !isMenuStatusEnabled || !hasNextAvailableTime;
              },
            });
          }

          bindItem(MENU_WIDGET_COMPONENT_IDS.menuDivider, {
            collapsed: () => !menusState.getMenu(itemData.id)?.menuDto?.sections.length,
          });

          bindItem(MENU_WIDGET_COMPONENT_IDS.sectionContainer, {
            data: () => {
              const values = {
                sections: menusState.getMenu(itemData.id)?.menuDto?.sections,
                menuId: menusState.getMenu(itemData.id)?.menuDto?.id,
              };
              return values;
            },
          });
        },
      },
    });
  }

  scrollToMenu = ({ menuId }: { menuId: string }) => {
    this.$w(MENU_WIDGET_COMPONENT_IDS.repeaterMenus).forItems([menuId], ($item: I$W) => {
      $item(MENU_WIDGET_COMPONENT_IDS.menuTitle).scrollTo();
    });
  };
}
